@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 768px)
  and (-webkit-min-device-pixel-ratio: 2) {
		.logo-nav-bar{
			width: 30px  !important;;
		}
		
}
.navbar{
	padding: 0rem 0rem !important;
}

.logo-nav-bar{
	border-radius : 100%;
	width: 40px;
}
.nav-link{
	width : 100% !important;
}