/*Content Panel*/
.content-panel {
    background: #ffffff;
    box-shadow: 0px 3px 2px #aab2bd;
    padding-top: 25px;
    padding-bottom: 5px;
    margin-top: 15px;
    border-radius: 5% !important;
}
.content-panel h4 {
    margin-left: 10px;
  
}
/*Panel Size*/

.pn {
    height: 270px;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.pn:hover {
    box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.3);
}
.img-project{
    width: 50%;
    margin: auto;
    padding-top: 4%;
}
#blog-bg {
    /*background: url(../images/Salesforce_Logo.png) no-repeat center top;*/
    margin-top: -15px;
    background-attachment: relative;
    background-position: center center;
    min-height: 150px;
    width: 100%;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.blog-text {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 15px;
    font-size: 12px;
}

.blog-text > .title-project {
    margin-bottom : 0px;
}
.modal-title-project{
    color :#45C1E1;
}
.img-project{
    width: 50%;
    margin: auto;
    padding-top: 4%;
}