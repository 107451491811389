
/* Typography */
html {
	font-family: 'Roboto', sans-serif;
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 700px)
  and (-webkit-min-device-pixel-ratio: 2) {
		.img-mobile{
			width : 100%;	
		}
		.img-mobile-trailhead{
			width : 60%;	
		}
		.size-image-full{
			width: 70% !important;
		}
		.img-malt {
			width: 50% !important;
		}
		
		.nav-link{
			margin-left: 5%!important;
		}
	
}
@media (min-width: 576px) {
	html {
		font-size: 14px;
	}
}

@media (min-width: 768px) {
	html {
		font-size: 16px;
	}

}

@media (min-width: 992px) {
	html {
		font-size: 18px;
	}
}

@media (min-width: 1200px) {
	html {
		font-size: 20px;
	}
}

.icons-social i {
	font-size: 3em;
}

/* Custom Styles */



.intro-header-home {
	background: url(/images/hero_bg.jpg) no-repeat center center;
	background-size: cover;
}
/* Block of page */
.block,
.certification-block,
 .main-home  {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	justify-content: center;
	padding: 0 30px;
	text-align: center;
}

 .block > .intro,
 .main-home .intro,
.certification-block > .intro {
	font-family: 'Reem Kufi', sans-serif;
	font-size: 3.0em;
	font-weight: 600;
}

.block > .tagline,
.main-home > .tagline,
.certification-block > .tagline  {
	font-size: 1.5rem;
  /* margin-bottom: 1.5rem; */
	font-weight: 100;
}

.devto {
	margin-bottom: -0.20rem;
}

.devto svg {
	margin-bottom: -0.20rem;
	margin-left: 0.675rem;;
	width: 2.65rem;
	height: 2.65rem;
}
/*  Image About me */

.size-image-full{
	width: 30%;
	border-radius: 100%;
}
.img-malt{
	width : 10%;
}
.certif-img-salesforce{
	border-radius: 3%;
}